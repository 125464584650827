﻿/* ----------------------------------------- */
/* Footer
/* ----------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}

body {
  /* Margin bottom == footer height */
  margin-bottom: 3rem !important;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3rem;
}
