﻿/* ----------------------------------------- */
/* Font
/* ----------------------------------------- */
$font-family-base: "Sarabun";
$font-weight-bold: 700;

/* ----------------------------------------- */
/* Colors
/* ----------------------------------------- */
// https://coolors.co
// https://color.adobe.com/de/create/color-wheel
// https://www.schemecolor.com/new-pastels-rainbow.php

$primary-100: #E0ECE5;
$primary-200: #C8E5D3;
$primary-300: #9DDEB7;
$primary-400: #4DCB80;
$primary-500: #14B655;
$primary-600: #138842;
$primary-700: #106F36;
$primary-800: #004D1F;
$primary-900: #002910;
$primary: $primary-500;

$secondary-100: #F3F3F3;
$secondary-200: #DFDFDF;
$secondary-300: #CFCFCF;
$secondary-400: #B8B8B8;
$secondary-500: #989898;
$secondary-600: #777777;
$secondary-700: #5F5F5F;
$secondary-800: #474747;
$secondary-900: #292929;
$secondary: $secondary-500;

$gray-100: $secondary-100;
$gray-200: $secondary-200;
$gray-300: $secondary-300;
$gray-400: $secondary-400;
$gray-500: $secondary-500;
$gray-600: $secondary-600;
$gray-700: $secondary-700;
$gray-800: $secondary-800;
$gray-900: $secondary-900;

$success-100: #DEF6EF;
$success-300: #80D9BF;
$success-500: #23B88B;
$success-700: #0D835F;
$success-900: #0D563F;
$success: $success-500;

$warning-100: #FFF4D6;
$warning-300: #F8D371;
$warning-500: #FF9B00;
$warning-700: #D85F00;
$warning-900: #A33906;
$warning: $warning-500;

$danger-100: #FFDEE6;
$danger-300: #F67795;
$danger-500: #DD0034;
$danger-700: #9D0D2F;
$danger-900: #67061D;
$danger: $danger-500;

$info-100: #E6E7FA;
$info-300: #9096EE;
$info-500: #3C47FF;
$info-700: #1F28B9;
$info-900: #00055A;
$info: $info-500;

/* ----------------------------------------- */
/* Misc overrides
/* ----------------------------------------- */
$border-radius: 0.3rem;
$enable-negative-margins: true;
$enable-shadows: true;

/* ----------------------------------------- */
/* Navbar
/* ----------------------------------------- */
$navbar-padding-y: 2.0625rem;
$navbar-brand-margin-end: 4rem;
$nav-link-color: $gray-400;
$nav-link-hover-color: $primary;
$navbar-nav-link-padding-x: 1.5625rem;
//$dropdown-link-hover-color: $primary-700;
//$dropdown-link-hover-bg: $primary-200;

/* ----------------------------------------- */
/* Modal
/* ----------------------------------------- */
$modal-header-border-width: 0;
$modal-footer-border-width: 0;
$modal-content-border-radius: .3125rem;
$modal-content-inner-border-radius: 0;
$modal-header-padding-x: 2rem;
$modal-header-padding-y: 1.6rem;
$modal-inner-padding: 2rem;

/* ----------------------------------------- */
/* Buttons
/* ----------------------------------------- */
$btn-font-weight: $font-weight-bold;
$btn-padding-x: 1.25rem;
$btn-padding-y: 0.563rem;

/* ----------------------------------------- */
/* Inputs
/* ----------------------------------------- */
$input-padding-x: 1.25rem;
$input-padding-y: 0.5rem;
$input-color: #1B1B1B;
$input-bg: $gray-100;
$input-border-color: $gray-200;
$input-focus-border-color: $primary-300;
$input-box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.05);

/* ----------------------------------------- */
/* Tables
/* ----------------------------------------- */
$table-hover-bg: $primary-100;

/* ----------------------------------------- */
/* Popover
/* ----------------------------------------- */
$popover-body-color: $gray-700;
$popover-border-width: 0;
$popover-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
$popover-bg: $gray-100;

/* ----------------------------------------- */
/* Drop down
/* ----------------------------------------- */
$dropdown-color: $gray-700;
$dropdown-bg: $gray-100;
$dropdown-border-width: 0;
$dropdown-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
$dropdown-link-hover-color: $primary-800;
$dropdown-link-hover-bg: $primary-100;
$dropdown-link-active-color: $primary-600;
$dropdown-link-active-bg: $primary-100;

/* ----------------------------------------- */
/* Switch
/* ----------------------------------------- */
$form-switch-color: $gray-300;
$form-switch-focus-color: $primary-300;
$form-switch-checked-color: $primary-100;

$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><rect x='-3.5' y='-3.5' width='7' height='7' rx='2.2' fill='#{$form-switch-color}'/></svg>");
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><rect x='-3.5' y='-3.5' width='7' height='7' rx='2.2' fill='#{$form-switch-focus-color}'/></svg>");
$form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><rect x='-3.5' y='-3.5' width='7' height='7' rx='2.2' fill='#{$form-switch-checked-color}'/></svg>");
