/* You can add global styles to this file, and also import other style files */

/* ----------------------------------------- */
/* Font
/* ----------------------------------------- */
@import "./styles/fonts";

/* ----------------------------------------- */
/* Bootstrap + Icons
/* ----------------------------------------- */
@import "./styles/theme.overrides";
//$enable-reduced-motion: false; // DEV only
@import "bootstrap/scss/bootstrap";
@import "./styles/theme.styles";

/* ----------------------------------------- */
/* Fix flickering on show/hide scrollbar.
/* Currently done by bootstrap.
/* ----------------------------------------- */
//html {
//  // Fix flickering when page scrollbar appears/disappears (e.g. modal dialog is opened).
//  // See https://stackoverflow.com/a/59407573/1271211, https://aykevl.nl/2014/09/fix-jumping-scrollbar
//  margin-left: calc(100vw - 100%);
//  margin-right: 0;
//  overflow-y: auto;
//}

/* ----------------------------------------- */
/* Custom extensions
/* ----------------------------------------- */
.cursor-pointer {
  cursor: pointer;
}

a {
  cursor: pointer;
}

.disabled {
  pointer-events: none;
  cursor: default;
}


/* ----------------------------------------- */
/* Bootstrap-icons
/* ----------------------------------------- */
.bi {
  width: 1em;
  height: 1em;
  vertical-align: -0.125em;
}

.bi.action-icon {
  height: 1.2em;
  width: 1.2em;
  vertical-align: -0.25em;
}

.btn .bi.check-lg {
  margin-top: -0.25rem;
  margin-bottom: -0.35rem;
  font-size: 2em;
  margin-right: 0.2rem;
}

/* ----------------------------------------- */
/* Bootstrap-datepicker
/* ----------------------------------------- */
@import "bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css";
.datepicker table tr td.disabled, .datepicker table tr td.disabled:hover {
  color: #E0E0E0;
}

/* ----------------------------------------- */
/* Popover
/* ----------------------------------------- */
.popover.popup-full-width {
  max-width: 100%;
}

/* ----------------------------------------- */
/* Toastr
/* ----------------------------------------- */
@import "./styles/toastr";

/* ----------------------------------------- */
/* ng-select
/* ----------------------------------------- */
@import "./styles/ng-select";

/* ----------------------------------------- */
/* Form validation
/* ----------------------------------------- */
@import "./styles/form-validation";

/* ----------------------------------------- */
/* Table
/* ----------------------------------------- */
@import "./styles/table";

/* ----------------------------------------- */
/* Footer
/* ----------------------------------------- */
@import "./styles/footer";
